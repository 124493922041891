<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <CDataTable
      clickable-rows
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{
        label: 'Filter:',
        placeholder: '...',
        external: true,
        lazy: true
      }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No linked categories found!',
      }"
      @row-clicked="rowClicked"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @update:column-filter-value="columnFilter"
      @pagination-change="paginationChange"
    >

      <!-- Check Box -->
      <template #select="{item}">
        <td>
          <CInputCheckbox
            v-if="!item.eol"
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <!-- Move Index -->
      <template #move_index="{ item }">
        <td class="move-item">
          <CIcon v-if="!item.eol" name="cil-move" :data-id="item.id" class="align-bottom" />
        </td>
      </template>

      <!-- Index -->
        <template #index="{ item }">
        <td>
          {{ item.index }}
        </td>
      </template>

      <!-- Image -->
      <template #image="{ item }">
        <td>
          <div v-if="item.image" class='image-container'>
            <img
              :lazy="true"
              v-img="{ src: item.image?.image_url }"
              :src="item.image?.image_url.replace('_desktop.', '_mobile.')"
            />
            <div class="action" @click="selectNewImage">✎</div>
          </div>
        </td>
      </template>

      <!-- Category Name -->
      <template #category_name="{item}">
        <td>
          <CLink v-if="item.valid_days_of_week?.length" v-c-tooltip="'Day constraints'" :to="{ name: 'EditLinkedCategory', params:{ id: item.id }}">
            <CIcon name="cil-warning" class="text-danger" />
          </CLink>

          <CLink :to="{ name: 'RestaurantLinkedMenus', params: { restaurant_id: item.restaurant_id }, query: { category: item.id }}">
            {{ item.category_name }}
          </CLink>
        </td>
      </template>

      <!-- Menu Count -->
      <template #menu_count="{item}">
        <td>
          <CLink :to="{ name: 'RestaurantLinkedMenus', params: { restaurant_id: item.restaurant_id }, query: { category: item.id }}" class="count bg-dark">
            {{item.menus_count}}
          </CLink>
        </td>
      </template>

      <!-- Note Public -->
      <template #note_public="{item}">
        <td>
          {{ item.note_public }}
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="py-2 text-center unstrikeout">
          <CButton
            v-if="!item.eol"
            size="sm"
            color="success"
            shape="pill"
            :variant="item.prices.length ? '' : 'ghost'"
            v-c-tooltip="{ html: true, content: 'Prices' }"
            @click="showPrices(item)"
            ><CIcon name="cil-euro" />
          </CButton>

          <CButton size="sm"
            :to="{ name: 'EditLinkedCategory', params: { id: item.id }}"
            color="info"
            shape="pill"
            variant="ghost"
            class="enable-item"
            ><CIcon name="cil-align-left" />
          </CButton>

          <CButton
            v-if="!item.eol"
            size="sm"
            color="danger"
            shape="pill"
            variant="ghost"
            @click="deleteItem(item, index)"
            ><CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CModal class="replicate-category"
      title="Replicate Category"
      color="success"
      :show.sync="replicateCategoryModal"
      :closeOnBackdrop="false"
      centered
    >
      <div class="form-group">
        <label class="d-block" style="font-weight: 500">Target Restaurant:</label>
        <v-select
          class="v-select-filter"
          placeholder="Select restaurant.."
          v-model="selectedRestaurant"
          :options="allRestaurants"
        />
      </div>

      <template #footer class="p-1">
        <CButton @click="replicateCategoryModal = false" color="link">Cancel</CButton>
        <CButton @click="replicateCategory" :disabled="selectedRestaurant === null" size="sm" color="success">
          <CIcon name="cil-clone" /> Replicate
        </CButton>
      </template>
    </CModal>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div class="d-flex align-items-center flex-gap-2">
            <CButton
              @click="reIndex"
              color="info"
              size="sm"
              :disabled="showTrashed"
            >
              <CIcon name="cil-list-numbered" class="mb-1"/>&nbsp;Re-Index
            </CButton>

            <CButton
              @click="replicateCategoryModal = true"
              color="success"
              size="sm"
              :disabled="selectedItem === null || showTrashed"
            >
              <CIcon name="cil-clone" class="mb-1"/>&nbsp;Replicate Category
            </CButton>

            <CInputCheckbox
              label="Show Trashed"
              custom
              :checked.sync="showTrashed"
              @update:checked="getAllItems"
            />
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <ProductPrices :item.sync="selectedItem" priceable="category" />
    <SelectImage :imageId="selectedItem?.image_id" @update="selectedNewImage"/>
  </CCardBody>
</template>

<script>
import Sortable from "sortablejs";
import ProductPrices from "@/components/ProductPrices";
import SelectImage from "./SelectImage"

export default {
  name: "BackendTable",
  components: {
    ProductPrices,
    SelectImage
  },
  data() {
    return {
      selectedItem: null,
      loadedItems: [],
      fields: [
        { key: "select",                  label: "",            filter: false, sorter: false, _style: "width: 1%" },
        { key: "move_index",              label: "",            filter: false, sorter: false, _style: "width: 5%" },
        { key: "index",                   label: "Index",       filter: false, _style: "width: 5%" },
        { key: "image",                   label: "",            filter: false, sorter: false, _style: "min-width: 125px; width: 125px;" },
        { key: "category_name",           label: "Name",        filter: false, _style: "min-width: 200px; width: 200px;" },
        { key: "menu_count",              label: "Menu Count",  filter: false, _style: "width: 10%" },
        { key: "note_public",             label: "Note Public", filter: false, _style: "width: 50%" },
        { key: "actions",                 label: "Actions",     filter: false, sorter: false, _style: "min-width: 140px; width: 140px; text-align: center;" }
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Category replicate (clone) işlemleri için
      selectedItem: null,
      replicateCategoryModal: false,
      allRestaurants: [],
      selectedRestaurant: null,

      showTrashed: false,
    };
  },
  mounted() {
    this.activePage =  this.queryPage;
    this.getAllItems();

    //#region Sortablejs işlemleri
    var itemID, newIndex;
    var el = document.getElementsByTagName("tbody");

    var sortable = Sortable.create(el[0], {
      handle: "svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(
            parseInt(itemID),
            newIndex + ((this.activePage - 1) * this.itemsPerPage)
          ),
      },
    });
    //#endregion Sortablejs ---
  },
  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'RestaurantLinkedCategories', params: { restaurant_id: this.restaurantId }, query: { page: this.activePage } });

      this.onTableChange();
    },
    async replicateCategoryModal(newVal, oldVal) {
      // newVal && this.allRestaurants.length == 0 && await this.getAllRestaurants();
      if (newVal) {
        this.allRestaurants.length == 0 && await this.getAllRestaurants();
        this.selectedRestaurant = this.allRestaurants.find(item => item.value == this.restaurantId);
      }
    }
  },
  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    host() { return this.$backend.HOST; },
    restaurantId () { return this.$route.params.restaurant_id },
  },
  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.RESTAURANT_LINKED_CATEGORIES.GET_ALL.replace("{restaurant_id}", this.restaurantId)),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          "show-trashed": this.showTrashed
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      // Sortablejs işleminden sonra sayfa geçişlerinde index değerinde göre sıralanmış ögeler
      // düzensiz sırada geliyordu. Bunu önlemek için loadedItems dizisini sıfırlamak gerekti.
      // Sortablejs kullanılmayacaksa aşağıda satır kaldırılabilir.
      this.loadedItems = []

      this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = item.eol == 1 ? 'strikeout disable-item ' : '';
              if (item.valid_days_of_week?.length) {
                item._classes += 'table-warning ';
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.search = search;
      this.getAllItems();
    },
    columnFilter(filter) {
      //console.log(JSON.stringify(filter))
      // Object.keys(filter).forEach((key) => {
      //   console.log(key, filter[key]);
      // });
    },
    rowClicked (item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check (item) {
      this.$set(item, "_selected", !item._selected);
      // v1
      // item._classes = item._selected ? 'table-selected' : '';

      // v2
      // item._classes = item._selected
      //   ? item._classes.concat(" table-selected")
      //   : item._classes.replace(" table-selected", "");

      // v3
      if (item._selected) {
        // Sadece bir adet item seçilsin.
        if(this.selectedItem) {
          this.selectedItem._selected = false;
          this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
        }

        item._classes = item._classes.concat(" table-selected");
        this.selectedItem = item;
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItem = null;
      }
    },

    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ The category and all related menus will be deleted! Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.RESTAURANT_LINKED_CATEGORIES.DELETE.replace("{restaurant_id}", this.restaurantId).replace("{id}", item.id)
              )
              .then((response) => {
                // if (response.data.soft === true) {
                //   item.menus_count = response.data.menu_count;
                //   item._classes = 'strikeout disable-item';
                // } else {
                //   this.$delete(this.loadedItems, index);
                //   this.total--;
                // }
                this.$delete(this.loadedItems, index);
                this.total--;

                this.showErrorAlert = false
                this.successAlertMessage = {itemName: item.category_name, message: 'linked category deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    storeNewIndexes(itemID, newIndex) {
      // console.log(itemID, newIndex);

      if(isNaN(itemID) || isNaN(newIndex))
        return;

      this.loading = true;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.loadedItems.find(item => item.id == itemID);
      var oldIndex = item.index;
      if (newIndex > oldIndex) {
        this.loadedItems.filter((item) => {
          return item.index > oldIndex && item.index <= newIndex;
        }).forEach((value, index) => { value.index--; });
      }
      else if (newIndex < oldIndex) {
        this.loadedItems.filter((item) => {
          return item.index < oldIndex && item.index >= newIndex;
        }).forEach((value, index) => { value.index++; });
      }
      item.index = newIndex;

      // For Back-End
      this.$axios
        .get(this.$backend.RESTAURANT_LINKED_CATEGORIES.STORE_NEW_INDEXES.replace("{restaurant_id}", this.restaurantId), {
          params: { itemID, newIndex: newIndex, restaurant_id: this.restaurantId },
        })
        // .then(this.getAllItems)
        // .then((response) => {console.log(response.data);})
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reIndex() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you want to re-index all items?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios.get(this.$backend.RESTAURANT_LINKED_CATEGORIES.RE_INDEX.replace("{restaurant_id}", this.restaurantId))
              .then((response) => {
                if (response.data) {
                  this.getAllItems();
                  this.successAlertMessage = { itemName: 'Success:', message: 'All items reordered successfully.' };
                  this.showErrorAlert = false;
                  this.dismissSuccessAlert = this.dismissSecs;
                }
                else {
                  this.errorAlertMessage = { 'No re-indexed': 'All items are already sorted correctly!' };
                  this.showErrorAlert = true;
                }
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                this.loading = false;
                window.scrollTo(0,0);
              });
            // ---------------
          }
        },
      });
    },

    replicateCategory() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you want to replicate the category?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.replicateCategoryModal = false;
            this.loading = true;
            this.$axios.post(this.$backend.RESTAURANT_LINKED_CATEGORIES.REPLICATE.replace("{restaurant_id}", this.restaurantId), {
              'category_id':this.selectedItem.id,
              'target':this.selectedRestaurant.value
            })
              .then((response) => {
                this.getAllItems();
                this.showErrorAlert = false;
                this.successAlertMessage = { itemName: 'Success:', message: response.data.success };
                this.dismissSuccessAlert = this.dismissSecs;
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                this.loading = false;
                window.scrollTo(0,0);
                this.selectedItem = null
                this.selectedRestaurant = null
              });
            // ---------------
          }
        },
      });
    },

    async getAllRestaurants() {
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;
        })
        .catch(() => { this.allRestaurants = [] })
    },

    showPrices(item) {
      this.selectedItem = item;
      this.$store.state.productPricesShow = true;
    },

    selectNewImage() {
      this.$store.commit('set', ['selectNewImageAsideShow', !(this.selectedItem)]);
    },
    async selectedNewImage(image) {
      const url = this.$backend.RESTAURANT_LINKED_CATEGORIES.UPDATE
        .replace("{restaurant_id}", this.selectedItem.restaurant_id)
        .replace("{id}", this.selectedItem.id);

      await this.$axios.put(url, {
        'image_id': image.id,
        'category_name': this.selectedItem.category_name,
        'eol': !(this.selectedItem.eol),
      })
        .then(() => {
          this.selectedItem.image = image;
        })
        .finally(() => {
          this.check(this.selectedItem);
        })
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.375rem 0.25rem;
  vertical-align: middle;
}
</style>

<style scoped>
.replicate-category .modal-header {
  padding: 0.75rem !important;
}
.replicate-category .modal-footer {
  padding: 0.25rem !important;
}

.image-container img {
  outline: 1px solid #ccc;
  padding: 1px;
  max-width: 100%;
  height: 2rem;

  transition: all 0.4s ease-in-out;
  backface-visibility: hidden;
}
.image-container {
  position: relative;
  display: inline-block;
}
.image-container .action {
  border: none;
  display: none;

  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: calc(100% - 18px);
  border-radius: 30px;
  padding: 5px 10px;
  background-color: #3498db;
  color: #fff;
}
.image-container:hover img {
  opacity: 0.3;
}
.image-container:hover .action {
  display: block;
}
</style>
