<template>
  <CModal
    class="prices-modal"
    title="Special Prices"
    color="dark"
    :show.sync="active"
    centered
    :closeOnBackdrop="false"
  >
    <template v-if="item">
      <div class="alert alert-info p-2">
        <h4 class="my-0">{{ item[`${priceable}_name`].toUpperCase() }}</h4>
        <div v-if="priceable == 'menu'"  class="d-flex flex-column flex-md-row border-top pt-3">
          <h5 class="flex-fill my-0">Delivery Price: {{ item?.price | toCurrency }}</h5>
          <h5 class="flex-fill my-0">Takeout Price: {{ item?.toPrice | toCurrency }}</h5>
        </div>
      </div>

      <div class="mt-3 mb-2 d-flex justify-content-between">
        <CButton @click="getForm()" :disabled="!!form" class="ml-auto" color="success" shape="pill" variant="ghost">
          <CIcon name="cil-plus" class="align-bottom" /> Add New
        </CButton>
      </div>

      <CCollapse :show="!!form">
        <CCard border-color="success">
          <CCardBody class="p-2">
            <form-summary
              v-if="showFormSummary"
              class="form-errors alert alert-danger"
              :validator="$v.form"
              :attributes="attributes"
            />

            <CAlert v-if="form?.discount_type === 'discount_price'" color="warning animated fadeIn">
              <strong>Caution!</strong> Please enter the discount amount, not the new price of the product.
              <i>For example, if a pizza goes from €6 to €5, enter the discount amount of €1.</i>
            </CAlert>

            <CForm @submit.prevent="submit()">
              <div class="d-flex flex-column">
                <div class="form-group w-100" :class="{ 'price-type': form?.id }">
                  <label class="d-block">Price Type:</label>
                  <CInputRadioGroup
                    name="price_type"
                    :options="[
                      { value: 'delivery', label: 'Delivery' },
                      { value: 'takeout', label: 'Takeout' },
                      { value: 'both', label: 'Both', disable: true },
                    ]"
                    :checked.sync="$v.form.price_type.$model"
                    custom
                    inline
                  />
                </div>

                <div class="d-flex flex-column flex-md-row">
                  <CSelect
                    class="w-100 mr-2"
                    label="Discount Type"
                    placeholder="Please select.."
                    :value.sync="$v.form.discount_type.$model"
                    :options="[
                      {value: 'fixed_price', label: 'Fixed Price' },
                      {value: 'discount_price', label: 'Discount Price' },
                      {value: 'discount_percentage', label: 'Discount Percentage' },
                    ]"
                  />

                  <CInput
                    class="w-100"
                    label="Price"
                    placeholder="0.00"
                    :value.sync="$v.form.price.$model"
                    type="number"
                    step="0.01"
                    pattern="^\d+(?:\.\d{1,2})?$"
                  >
                    <template #prepend-content>
                      <strong v-if="form?.discount_type === 'discount_percentage'">%</strong>
                      <CIcon v-else name="cil-euro"/>
                    </template>
                  </CInput>
                </div>

                <CSelect
                  class="w-100"
                  label="Day of Week"
                  placeholder="Please select.."
                  :value.sync="$v.form.day_of_week.$model"
                  :options="[
                    {value: 1, label: 'Monday' },
                    {value: 2, label: 'Tuesday' },
                    {value: 3, label: 'Wednesday' },
                    {value: 4, label: 'Thursday' },
                    {value: 5, label: 'Friday' },
                    {value: 6, label: 'Saturday' },
                    {value: 0, label: 'Sunday' },
                  ]"
                />

                <div class="d-flex flex-row flex-gap-1 justify-content-end">
                  <CButton @click="reset()" size="sm" color="link">Cancel</CButton>
                  <CButton :disabled="loading" type="submit" size="sm" color="success">{{ form?.id ? 'Update' : 'Add' }}</CButton>
                </div>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCollapse>

      <CDataTable
        class="prices-table"
        hover
        striped
        :items="item?.prices"
        :fields="tableFields"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'Special price not found!',
        }"
      >
        <template #price_type="{item}">
          <td>
            {{ item.price_type | ucfirst }}
          </td>
        </template>

        <template #price="{item}">
          <td>
            <h5 class="mb-0">
              <span v-if="item.discount_type === 'fixed_price'">{{ item.price | toCurrency }}</span>
              <span v-else-if="item.discount_type === 'discount_price'">{{ -1 * item.price | toCurrency('discount_price') }}</span>
              <span v-else-if="item.discount_type === 'discount_percentage'">{{ item.price | toCurrency('discount_percentage') }}</span>
            </h5>
          </td>
        </template>

        <template #valid_date="{item}">
          <td>
            <strong>
              {{ moment().day(item.day_of_week).format('dddd') }}
            </strong>
          </td>
        </template>

        <template #actions="{ item, index }">
          <td class="py-2">
            <CButton
              size="sm"
              color="info"
              shape="pill"
              variant="ghost"
              @click="getForm(item)"
            ><CIcon name="cil-align-left" />
            </CButton>

            <CButton
              size="sm"
              color="danger"
              shape="pill"
              variant="ghost"
              class="ml-1"
              @click="deleteItem(item, index)"
            ><CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </CDataTable>
    </template>

    <template #footer class="p-1">
      <CButton @click="active=false" color="secondary">Close</CButton>
    </template>
  </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minValue } from "vuelidate/lib/validators"

export default {
  name: "ProductPrices",
  props: {
    item: { default: null, type: Object },
    priceable: { default: 'menu', type: String },
  },

  data() {
    return {
      loading: false,

      tableFields: [
        { key: "actions",     label: "Actions",     filter: false, sorter: false, _style: "min-width: 96px; width: 96px; text-align: center;" },
        { key: "price_type",  label: "Type",        filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "price",       label: "Price",       filter: false, _style: "min-width: 100px;" },
        { key: "valid_date",  label: "Valid Date",  filter: false, _style: "min-width: 120px;" },
      ],

      form: null,
      attributes: {
        price: 'Price',
        price_type: 'Price type',
        day_of_week: 'Day of week',
        discount_type: 'Discount Type',
      },
    };
  },

  mixins: [validationMixin],
  validations: {
    form: {
      price: { required, minValue: minValue(0.01) },
      price_type: { required },
      day_of_week: { required },
      discount_type: { required },
    }
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    active: {
      get: function () { return this.$store.state.productPricesShow },
      set: function (newValue) { this.$store.state.productPricesShow = newValue }
    },
    showFormSummary() { return this.form && !this.isValid && this.isDirty },
  },

  watch: {
    active: async function (val) {
      this.reset();
    },
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    validate () {
      this.$v.$touch()
    },

    getForm (item = null) {
      var form = {
        priceable: this.priceable,
        'priceable-id': this.item.id,
        price: null,
        price_type: 'delivery',
        day_of_week: null,
        discount_type: 'fixed_price',
      };

      if(item) {
        form.id = item.id;
        form.price = item.price;
        form.price_type = item.price_type;
        form.day_of_week = item.day_of_week;
        form.discount_type = item.discount_type;
      }

      this.form = form;
    },

    async submit() {
      if (!this.isValid) {
        this.validate();
        return;
      }

      if (this.form?.id) {
        await this.update();
      } else {
        if (this.form.price_type === "both") {
          await Promise.all([
            this.storeWithPriceType("delivery"),
            this.storeWithPriceType("takeout"),
          ]);
        } else {
          await this.store();
        }
      }
    },

    async store() {
      this.loading = true;
      await this.$axios.post(this.$backend.PRICES.STORE.replace("{restaurant_id}", this.item.restaurant_id), this.form)
        .then((response)=>{
          this.item.prices.push(response.data);
          this.reset();
        })
        .catch((error)=>{
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async storeWithPriceType(priceType) {
      this.form.price_type = priceType;
      await this.store();
    },

    async update() {
      this.loading = true;
      await this.$axios.put(this.$backend.PRICES.UPDATE.replace("{restaurant_id}", this.item.restaurant_id).replace("{id}", this.form.id), this.form)
        .then((response)=>{
          this.item.prices = this.item.prices.map(price =>
            price.id === this.form.id ? { ...response.data } : price
          );
          this.reset();
        })
        .catch((error)=>{
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(item, index) {
      this.reset();

      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.PRICES.DELETE.replace("{restaurant_id}", this.item.restaurant_id).replace("{id}", item.id)
              )
              .then((response) => {
                this.$delete(this.item.prices, index);
                this.$toast.success(`The special price has been deleted.`);
              })
              .catch((error) => {
                this.$toast.error(`${error.response.data.message}`);
              })
              .finally(() => {
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    reset() {
      this.form = null
      this.$v.$reset()
    },
  },
};
</script>

<style>
.prices-modal .modal-header {
  padding: 0.75rem !important;
}
.prices-modal .modal-footer {
  padding: 0.25rem !important;
}

.prices-table table {
  margin-bottom: 0;
}

.prices-table table th, .prices-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}

.prices-modal .form-errors div:nth-child(1):before {
  display: block;
  content: "Plase check!";
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Price type: both */
.price-type .custom-radio:nth-child(3) {
  display: none;
  pointer-events: none;
  opacity: 0.6;
}

.prices-modal .form-control {
  font-weight: bold;
}
</style>
