<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-folder" />
          <h5 class="d-inline ml-2">
            <h3 class="d-inline"><CBadge color="secondary">{{ this.restaurantName | uppercase }}</CBadge></h3>
            Linked Categories
          </h5>
          <div class="card-header-actions">
            <CButton v-if="restaurantId" color="link" :to="{name: 'View Restaurant', params: {'id': restaurantId}}" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Restaurant
            </CButton>

            <CButton color="primary" :to="{ name: 'AddNewLinkedCategory'}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Linked Category
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "RestaurantLinkedCategories",
  components: {
    BackendTable,
  },
  data() {
    return {
      restaurantName: "",
    };
  },
  mounted() {
    this.getRestaurantName();
  },
  computed: {
    restaurantId() { return this.$route.params.restaurant_id; },
  },
  methods: {
    getRestaurantName() {
      this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId))
        .then((response) => {
          this.restaurantName = response.data.restaurant_name;
        })
        .catch((error) => { this.restaurantName = 'Not Found' })
    },
  },
};
</script>
