import { render, staticRenderFns } from "./BackendTable.vue?vue&type=template&id=3d4458bf&scoped=true"
import script from "./BackendTable.vue?vue&type=script&lang=js"
export * from "./BackendTable.vue?vue&type=script&lang=js"
import style0 from "./BackendTable.vue?vue&type=style&index=0&id=3d4458bf&prod&lang=css"
import style1 from "./BackendTable.vue?vue&type=style&index=1&id=3d4458bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4458bf",
  null
  
)

export default component.exports